import React from "react";
import ReactDOM from "react-dom/client";
//import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import "@rainbow-me/rainbowkit/styles.css";
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  trustWallet,
  connectorsForWallets
} from '@rainbow-me/rainbowkit/wallets'; // Import wallet connectors
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  bsc,
  sepolia,
} from "wagmi/chains";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { http } from "wagmi";

const WYZTH = {
  id: 303,
  name: "WYZth",
  network: "wyzthscan",
  // iconUrl: "https://wyzthscan.org/images/small-logo-icon.png",
  iconUrl:"./wy.png",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "WYZth",
    symbol: "wyzth",
  },
  rpcUrls: {
    public: {
      http: ["https://rpc-mainnet.wyzthchain.org"],
    },
  },
  blockExplorers: {
    default: { name: "WYZth", url: "https://wyzthscan.org/" },
  },
  testnet: false,
};
const config = getDefaultConfig({
  appName: "Staking Admin",
  projectId: "Staking Admin",
  chains: [bsc,  WYZTH],
  defaultChain: bsc,
  ssr: true,
  transports: {
    [bsc.id]: http("https://bsc-dataseed1.binance.org"),
    [sepolia.id]: http("https://eth-sepolia.g.alchemy.com/v2/..."),
  },
});
const chains = [bsc, mainnet, ];
const connectors = [
  injectedWallet({ chains }),
  rainbowWallet({ chains, projectId: "Staking Admin" }), 
  walletConnectWallet({ chains, projectId: "Staking Admin" }),
  metaMaskWallet({ chains, projectId: "Staking Admin" }),
  trustWallet({ chains, projectId: "Staking Admin" }),
];
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ThemeContext>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <RainbowKitProvider
              chains={[bsc, WYZTH, ]}
              wallets={connectors}
              >
                <App />
              </RainbowKitProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </ThemeContext>
      </BrowserRouter>
    </Provider>
    <NotificationContainer />
  </React.StrictMode>
);


reportWebVitals();
