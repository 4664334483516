import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Commissiondata_New } from "../../../../services/api_function";
import { useSelector } from "react-redux";

const CommissionDataNew = () => {
  const location = useLocation();
  const userGet = new URLSearchParams(location.search).get("user");
  const [user, setUser] = useState(userGet);
  const [userData, setUserData] = useState([]);

  const isInitialRender = useRef(true);
  const User = useMemo(() => user, [user]);
  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    Commissiondata_New(User, token)
      .then((response) => {
        setUserData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
  }, [User]);

 
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <i
                class="fas fa-circle-left "
                style={{ fontSize: "2rem" }}
                onClick={() => navigate(-1)}
              ></i>
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Commission
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>Total Income</strong>
                    </th>
                    <th>
                      <strong>Total Withdraw</strong>
                    </th>
                    <th>
                      <strong>Referral Income</strong>
                    </th>
                    <th>
                      <strong>Level Income</strong>
                    </th>
                    <th>
                      <strong>Recurring Bonuss</strong>
                    </th>
                    <th>
                      <strong>Pool Bonus</strong>
                    </th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td >
                      {(userData?.totalIncome ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.totalWithdraw ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.referalIncome ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.levelIncome ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.recurrIncome ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.poolbonus ?? 0).toFixed(2)}
                    </td>
                  
                
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CommissionDataNew;
