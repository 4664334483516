import React, { Fragment, useState } from "react";
import {
  WithdrawBlock,
  LavelBlock,
  FiftyActivate,
  FreeID,
  url,
  team_Busness,
  Update_Withdraw,
} from "../../../services/api_function";
import { format } from "date-fns";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaCalendarAlt } from "react-icons/fa";
// import { web3 } from "./web3/web3Helper";
import { NotificationManager } from "react-notifications";
import { checkUser, token_abi, token_address } from "../../config/config";
import { isRegisteredInContract, web3 } from "./web3/web3Helper";
import {
  farming_abi,
  contract_address,
  testing_abi,
  contract_testing,
} from "../../config/config";
import { toast } from "react-toastify";
import axios from "axios";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

const BlockData = () => {
  const [userInputBlock, setUserInputBlock] = useState("");
  const [userInputUnblock, setUserInputUnblock] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [showData, setShowData] = useState(false);
  const [showDataWyz, setshowDataWyz] = useState(false);
  const [userlebal, setuserlebal] = useState("");
  const [user, setUser] = useState("");
  const [plan1, setPlan1] = useState("");
  const [plan, setplan] = useState("");
  const [apiData, setapiData] = useState();
  const [apiDataWyz, setapiDataWyz] = useState();
  const [wysAmount, setWysAmount] = useState("");
  const [userInput, setUserInput] = useState("");
  const [userInputWyz, setuserInputWyz] = useState("");
  const [duration, setDuration] = useState("");
  const [user1, setUser1] = useState("");
  const [withdrawUser, setwithdrawUser] = useState("");
  const [withdrawAmount, setwithdrawAmount] = useState("");
  const [withdraWallet, setwithdraWallet] = useState("");
  const [action, setAction] = useState();
  const [wysAmount1, setWysAmount1] = useState("");
  const [duration1, setDuration1] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.auth.token);
  const { address } = useAccount();

  const matchedAddress =
    address &&
    (address.toLowerCase() === process.env.REACT_APP_WALLET_1.toLowerCase()
      ? process.env.REACT_APP_WALLET_1
      : address.toLowerCase() === process.env.REACT_APP_WALLET_2.toLowerCase()
        ? process.env.REACT_APP_WALLET_2
        : null);

  const isAddressMatched = !!matchedAddress;
 
  console.log(isAddressMatched, "isaddreshmatch")
  const handleBlock = () => {
    if (userInputBlock.trim() !== "") {
      if (isAddressMatched) {
        WithdrawBlock(userInputBlock, "1", token)
          .then((response) => {
            if (response.status == 200) {
              NotificationManager.success(response.message);
              setUserInputUnblock("");
            } else {
              NotificationManager.error(response.message);
            }
          })
          .catch((error) => {
            NotificationManager.error(error.message);
          });
      } else {
        NotificationManager.error("Not Allow");
      }
    } else {
      setErrorMessage("User input for blocking is empty!");
    }
  };

  const handleUnblock = () => {
    if (userInputUnblock.trim() !== "") {
      if (isAddressMatched) {
        WithdrawBlock(userInputUnblock, "0", token)
          .then((response) => {
            if (response.status == 200) {
              NotificationManager.success(response.message);
              setUserInputUnblock("");
            } else {
              NotificationManager.error(response.message);
            }
          })
          .catch((error) => {
            NotificationManager.error(error.message);
          });
      } else {
        NotificationManager.error("Not Allow");
      }
    } else {
      setErrorMessage("User input for blocking is empty!");
    }
  };

  const handleFree = async (e) => {
    e.preventDefault();
    const checksumAddress = web3.utils.toChecksumAddress(user1);

    if (checksumAddress.trim() !== "" && wysAmount1.trim() !== "" && plan1) {
      if (isAddressMatched) {
        FreeID(checksumAddress, wysAmount1, plan1, token,matchedAddress)
          .then((response) => {
            if (response) {
              NotificationManager.success(response.message);
              clearFormData();
            } else {
              NotificationManager.error(response.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        NotificationManager.error("Not Allow: Address does not match the authorized wallets.");
      }
    } else {
      setErrorMessage("User, WYS Amount, or Duration is empty!");
    }
  };


  const styletext = {
    fontSize: "20px",
    fontWeight: 600,
    color: "#000",
    textTransform: "capitalize",
  };

  const clearFormData = () => {
    setUser1("");
    setWysAmount1("");
    setDuration1("");
    setPlan1("");
  };

  const clearWithdraw = () => {
    setwithdrawUser("");
    setwithdrawAmount("");
    setwithdraWallet("");
    setAction();
  };

  const handleCalendarIconClick = () => {
    setIsCalendarOpen(true);
  };

  const handleCalendarChange = (date) => {
    setDateRange(date);
    setIsCalendarOpen(false);
  };

  const handleSubmitdata = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const [startDate, endDate] = dateRange.map((date) =>
        moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[+00:00]")
      );
      const user = web3.utils.toChecksumAddress(userInput);

      const response = await fetch(url + "/team-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user: user,
          startDate,
          endDate,
        }),
      });

      const data = await response.json();
      if (data) {
        setIsLoading(false);
        setapiData(data);
        setShowData(true);
      } else {
        setIsLoading(false);
        NotificationManager.error("No Data Found");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const withdrawUpdate = async (e) => {
    e.preventDefault();
    if (
      withdrawUser.trim() !== "" &&
      withdrawAmount.trim() !== "" &&
      withdraWallet.trim() &&
      action &&
      isAddressMatched
    ) {
      const user = web3.utils.toChecksumAddress(withdrawUser);
      Update_Withdraw(user, withdrawAmount, withdraWallet, token, action,matchedAddress)
        .then((response) => {
          if (response) {
            NotificationManager.success(response.message);
            clearWithdraw();

          } else {
            NotificationManager.error(response.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setErrorMessage("User, WYS Amount, or Duration is empty!");
    }
  };

  const handleSubmitdataWyz = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const [startDate, endDate] = dateRange.map((date) =>
        moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[+00:00]")
      );
      console.log(userInputWyz, "user");
      const user = web3.utils.toChecksumAddress(userInputWyz);

      const response = await fetch(url + "/team-data-wyz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user: user,
          startDate,
          endDate,
        }),
      });

      const data = await response.json();
      if (data) {
        setLoading(false);
        setapiDataWyz(data);
        setshowDataWyz(true);
      } else {
        setIsLoading(false);
        NotificationManager.error("No Data Found");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px" }}>
        <ConnectButton
          showBalance={false}
          chainStatus="icon"
          accountStatus="address"
        />
      </div>
      <div className="row">
        {/* {isAddressMatched ? (
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="center" style={{ margin: "auto" }}>
                Withdraw Block
              </h4>
            </div>

            <div className="card-body ">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control input-default"
                      placeholder="User"
                      value={userInputBlock}
                      onChange={(e) => setUserInputBlock(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleBlock}
                  >
                    Block
                  </button>
                </form>
              </div>
            </div>
          </div>
      
        </div>
          )} */}
        {isAddressMatched ? (
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="center" style={{ margin: "auto" }}>
                  Withdraw Block
                </h4>
              </div>

              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="User"
                        value={userInputBlock}
                        onChange={(e) => setUserInputBlock(e.target.value)}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleBlock}
                    >
                      Block
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>

          </p>
        )}
        {isAddressMatched ? (
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title center" style={{ margin: "auto" }}>
                  Withdraw Unblock
                </h4>
              </div>
              <div className="card-body ">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="User"
                        value={userInputUnblock}
                        onChange={(e) => setUserInputUnblock(e.target.value)}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleUnblock}
                    >
                      Unblock
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>

          </p>
        )}

        {isAddressMatched ? (
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title center" style={{ margin: "auto" }}>
                  50-50
                </h4>
              </div>
              <div className="card-body ">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="User"
                        value={user1}
                        onChange={(e) => setUser1(e.target.value)}
                      />
                    </div>
                    <div class="row">
                      <div className="form-group mb-3 col-lg-6">
                        <input
                          type="text"
                          className="form-control input-default"
                          placeholder=" Amount"
                          value={wysAmount1}
                          onChange={(e) => setWysAmount1(e.target.value)}
                        />
                      </div>
                      <div class="col-6">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={plan1}
                          onChange={(e) => setPlan1(e.target.value)}
                        >
                          <option selected>Plan</option>
                          <option value="1">WYZ+stUSDT(10:90)</option>
                          <option value="2">WYZ+stUSDT(20:80)</option>
                          <option value="3">WYZ+stUSDT(30:70)</option>
                          <option value="4">WYZ+stUSDT(40:60)</option>
                          <option value="5">WYZ+stUSDT(50:50)</option>
                          <option value="6">sUSDT+stUSDT(15:85)</option>
                          <option value="7">sUSDT+stUSDT(20:80)</option>
                          <option value="8">sUSDT+stUSDT(25:75)</option>
                        </select>
                      </div>
                      <div></div>
                      <button
                        type="button "
                        className="btn btn-success"
                        onClick={handleFree}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>

          </p>
        )}
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title center" style={{ margin: "auto" }}>
                Team Business
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmitdata}>
                  <div className="form-group mb-3"></div>
                  <div className="row">
                    <div className="form-group mb-3 col-lg-6">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="User"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-6">
                      <FaCalendarAlt
                        size={30}
                        onClick={handleCalendarIconClick}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {isCalendarOpen && (
                      <div className="Sample__container">
                        <Calendar
                          onChange={handleCalendarChange}
                          value={dateRange}
                          selectRange={true}
                        />
                      </div>
                    )}
                    <div className="col-6">
                      <button type="submit" className="btn btn-success">
                        {isLoading ? "Loading..." : "Fetch"}
                      </button>
                    </div>

                    {showData && (
                      <>
                        <div className="form-group mb-3 col-lg-6">
                          <div>
                            <h2 style={styletext}>
                              Team Business:{" "}
                              {Number(
                                (apiData?.totalAmount ?? 0) +
                                (apiData?.teamTopupAmount ?? 0)
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              TeamTopup:{" "}
                              {Number(apiData?.teamTopupAmount ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ: {Number(apiData?.toalwyz ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              stUSDT:{" "}
                              {Number(apiData?.toalstusdt ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              TotalWithdraw:{" "}
                              {Number(
                                apiData?.totalWithdrawAmount ?? 0
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WithdrawUser USdt:{" "}
                              {Number(
                                apiData?.userWithdrawUsdt ?? 0
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WithdrawUser WYZ:{" "}
                              {Number(
                                apiData?.wyzWithdrawUser ?? 0
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Total Withdraw Team:{" "}
                              {Number(
                                (apiData?.totalWithdrawdata || 0) * 0.95
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Total Withdraw Team WYZ:{" "}
                              {Number(
                                (apiData?.totalWyzWithdraw || 0) * 0.95
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              TotalDeposit:{" "}
                              {Number(
                                apiData?.totaldepositeAmount ?? 0
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Topup:{" "}
                              {Number(apiData?.totalTopupAmount ?? 0).toFixed(
                                2
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (10:90):{" "}
                              {apiData.totalWYZByProtocol &&
                                apiData.totalWYZByProtocol["10"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYZByProtocol["10"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalstudtProtocol &&
                                apiData.totalstudtProtocol["10"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalstudtProtocol["10"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "WYZ-stUSDT"
                                      ][10] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (20:80):{" "}
                              {apiData.totalWYZByProtocol &&
                                apiData.totalWYZByProtocol["20"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYZByProtocol["20"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalstudtProtocol &&
                                apiData.totalstudtProtocol["20"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalstudtProtocol["20"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "WYZ-stUSDT"
                                      ][20] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (30:70):{" "}
                              {apiData.totalWYZByProtocol &&
                                apiData.totalWYZByProtocol["30"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYZByProtocol["30"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalstudtProtocol &&
                                apiData.totalstudtProtocol["30"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalstudtProtocol["30"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "WYZ-stUSDT"
                                      ][30] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (40:60):{" "}
                              {apiData.totalWYZByProtocol &&
                                apiData.totalWYZByProtocol["40"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYZByProtocol["40"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalstudtProtocol &&
                                apiData.totalstudtProtocol["40"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalstudtProtocol["40"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "WYZ-stUSDT"
                                      ][40] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (50:50):{" "}
                              {apiData.totalWYZByProtocol &&
                                apiData.totalWYZByProtocol["50"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYZByProtocol["50"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalstudtProtocol &&
                                apiData.totalstudtProtocol["50"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalstudtProtocol["50"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "WYZ-stUSDT"
                                      ][50] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              sUSDT+stUSDT (15:85):{" "}
                              {apiData.totalWYSusdt &&
                                apiData.totalWYSusdt["15"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYSusdt["15"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalStudtUSDT &&
                                apiData.totalStudtUSDT["15"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalStudtUSDT["15"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["sUSDT-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "sUSDT-stUSDT"
                                      ][15] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              sUSDT+stUSDT (20:80):{" "}
                              {apiData.totalWYSusdt &&
                                apiData.totalWYSusdt["20"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYSusdt["20"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalStudtUSDT &&
                                apiData.totalStudtUSDT["20"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalStudtUSDT["20"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["sUSDT-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "sUSDT-stUSDT"
                                      ][20] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              sUSDT+stUSDT (25:75):{" "}
                              {apiData.totalWYSusdt &&
                                apiData.totalWYSusdt["25"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalWYSusdt["25"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalStudtUSDT &&
                                apiData.totalStudtUSDT["25"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalStudtUSDT["25"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiData.totalByProtocol &&
                                apiData.totalByProtocol["sUSDT-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiData.totalByProtocol[
                                      "sUSDT-stUSDT"
                                      ][25] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>UserId: {apiData?.data}</h2>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {isAddressMatched ? (
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title center" style={{ margin: "auto" }}>
                  Withdraw Update
                </h4>
              </div>
              <div className="card-body ">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="User"
                        value={withdrawUser}
                        onChange={(e) => setwithdrawUser(e.target.value)}
                      />
                    </div>
                    <div class="row">
                      <div className="form-group mb-3 col-lg-6">
                        <input
                          type="text"
                          className="form-control input-default"
                          placeholder=" Amount"
                          value={withdrawAmount}
                          onChange={(e) => setwithdrawAmount(e.target.value)}
                        />
                      </div>
                      <div class="col-6">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={withdraWallet}
                          onChange={(e) => setwithdraWallet(e.target.value)}
                        >
                          <option selected>Wallet Type</option>
                          <option value="wallet_roi">ROI</option>
                          <option value="wallet_referral">Referral</option>
                          <option value="wallet_recurr">Recurring</option>
                        </select>
                      </div>
                      <div class="col-6">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={action}
                          onChange={(e) => setAction(e.target.value)}
                        >
                          <option selected>Action</option>
                          <option value="-" className="text-center">
                            - (Subtract)
                          </option>
                          <option value="+" className="text-center">
                            +(Add)
                          </option>
                        </select>
                      </div>
                      <button
                        type="button "
                        className="btn btn-success"
                        onClick={withdrawUpdate}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>

          </p>
        )}
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title center" style={{ margin: "auto" }}>
                Team Business WYZ
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmitdataWyz}>
                  <div className="form-group mb-3"></div>
                  <div className="row">
                    <div className="form-group mb-3 col-lg-6">
                      <input
                        type="text"
                        className="form-control input-default"
                        placeholder="User"
                        value={userInputWyz}
                        onChange={(e) => setuserInputWyz(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-6">
                      <FaCalendarAlt
                        size={30}
                        onClick={handleCalendarIconClick}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {isCalendarOpen && (
                      <div className="Sample__container">
                        <Calendar
                          onChange={handleCalendarChange}
                          value={dateRange}
                          selectRange={true}
                        />
                      </div>
                    )}
                    <div className="col-6">
                      <button type="submit" className="btn btn-success">
                        {Loading ? "Loading..." : "Fetch"}
                      </button>
                    </div>

                    {showDataWyz && (
                      <>
                        <div className="form-group mb-3 col-lg-6">
                          <div>
                            <h2 style={styletext}>
                              Team Business:{" "}
                              {Number(
                                (apiDataWyz?.totalAmount ?? 0)

                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              UserId: {apiDataWyz?.data}
                            </h2>
                          </div>
                        </div>

                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ: {Number(apiDataWyz?.toalwyz ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              stUSDT:{" "}
                              {Number(apiDataWyz?.toalstusdt ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              TotalWithdraw:{" "}
                              {Number(
                                apiDataWyz?.totalWithdrawAmount ?? 0
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>

                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              TotalDeposit:{" "}
                              {Number(
                                apiDataWyz?.totaldepositeAmount ?? 0
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Total Withdraw Team:{" "}
                              {Number(
                                (apiDataWyz?.totalWithdrawdata || 0) * 0.95
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Withdraw Team apyUSDT:{" "}
                              {Number(
                                (apiDataWyz?.totalWithdrawWyz || 0) * 0.95
                              ).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Withdraw Team apyWYZ:{" "}
                              {Number((apiDataWyz?.roiWyz || 0) * 0.95).toFixed(
                                2
                              )}
                            </h2>
                          </div>
                        </div>
                        {/* <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (10:90):{" "}
                              {apiDataWyz.totalWYZByProtocol &&
                                apiDataWyz.totalWYZByProtocol["10"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiDataWyz.totalWYZByProtocol["10"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiDataWyz.totalstudtProtocol &&
                                apiDataWyz.totalstudtProtocol["10"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiDataWyz.totalstudtProtocol["10"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiDataWyz.totalByProtocol &&
                                apiDataWyz.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiDataWyz.totalByProtocol[
                                        "WYZ-stUSDT"
                                      ][10] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div> */}

                        {/* <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ+stUSD (50:50):{" "}
                              {apiDataWyz.totalWYZByProtocol &&
                                apiDataWyz.totalWYZByProtocol["50"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiDataWyz.totalWYZByProtocol["50"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiDataWyz.totalstudtProtocol &&
                                apiDataWyz.totalstudtProtocol["50"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiDataWyz.totalstudtProtocol["50"] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                              ,
                              {apiDataWyz.totalByProtocol &&
                                apiDataWyz.totalByProtocol["WYZ-stUSDT"] && (
                                  <span>
                                    {" "}
                                    {Number(
                                      apiDataWyz.totalByProtocol[
                                        "WYZ-stUSDT"
                                      ][50] ?? 0
                                    ).toFixed(2)}
                                  </span>
                                )}
                            </h2>
                          </div>
                        </div> */}
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ(wyz):{" "}
                              {(apiDataWyz?.totalWyzWyz ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ(USDT):{" "}
                              {(apiDataWyz?.totalWyzUsdt ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              WYZ(stUSDT):{" "}
                              {(apiDataWyz?.totalUsdtWyz ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>
                        <div className="form-group mb-3 col-lg-6 mt-2">
                          <div>
                            <h2 style={styletext}>
                              Usdt:{" "}
                              {(apiDataWyz?.totalUsdtUsdt ?? 0).toFixed(2)}
                            </h2>
                          </div>
                        </div>


                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlockData;
