import React, { Fragment, useEffect, useState, useMemo } from "react";
// import { ethers } from 'ethers';
import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";
import Web3 from "web3";
import { Row, Col, Card, Table, Modal, Button, Form } from "react-bootstrap";
import { url, WithdrawRefreal_USdtNew, withdrawRoistudst_Live } from "../../../../services/api_function";

import { web3 } from "../web3/web3Helper";

import {
    token_abi,
    token_address,
    transfer_abi,
    transfer_addres,
} from "../../../config/config";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { useSelector } from "react-redux";

export const WithdrawRefrealNew = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [approveLoading, setapproveLoading] = useState({});
    const [formData, setFormData] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isApproveBp, setApproveBp] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
    const limit = 50;
    useEffect(() => {
        fetchData();
    }, [currentPage, search]);

    const token2 = useSelector((state) => state.auth.auth.token);
    const fetchData = async () => {
        try {
            const response = await WithdrawRefreal_USdtNew(
                currentPage,
                search,
                limit,
                token2
            );

            setFilteredData(response?.data);
            const pages = Math.ceil(response?.totalCount / limit);
            setTotalPages(pages > 0 ? pages : 1);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage < totalPages ? prevPage + 1 : prevPage
        );
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };


    const handleSearch = async (e) => {
        const query = e.target.value.trim().toLowerCase();
        const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
        setSearch(sanitizedQuery);
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
    };

    const sendTransaction = async () => {
        console.log("in BEP 20 1");
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(transfer_abi, transfer_addres);
        const token = new web3.eth.Contract(token_abi, token_address);
        const networkId = await web3.eth.getChainId();
        const bscRPC = "https://bsc-dataseed.binance.org/";
        console.log(networkId, "network");
        if (Number(networkId) != 56) {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x38" }],
            });
        }

        console.log("in BEP 20 2");
        try {
            setApproveBp(true);
            console.log("in BEP 20 3");
            const resp = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            console.log("in BEP 20 4", resp);
            const accounts = await web3.eth.getAccounts();
            console.log("in BEP 20 5");
            const senderAddress = accounts[0];
            const recipients = selectedUsers.map((data) => data.user);
            const amounts = selectedUsers.map((data) => data.withdrawAmount * 0.95);
            const id = selectedUsers.map((data) => data._id.toString().slice(0, 1));
            const objid = selectedUsers.map((data) => data._id);
            const totalAmount = web3.utils.toWei(
                selectedUsers
                    .reduce((total, data) => total + data.withdrawAmount * 0.98, 0)
                    .toString(),
                "ether"
            );

            const refreshamount = web3.utils.toWei(
                selectedUsers
                    .reduce((total, data) => total + data.withdrawAmount * 1.1, 0)
                    .toString(),
                "ether"
            );

            const amountsInWei = amounts.map((amount) =>
                web3.utils.toWei(amount.toString(), "ether")
            );
            const gasPrice = await web3.eth.getGasPrice();

            console.log("Recipients:", recipients);
            console.log("Amounts (in Wei):", amountsInWei);
            console.log("Total Amount:", totalAmount);
            console.log("IDs:", id);
            console.log("Sender Address:", senderAddress);

            const balance = await web3.eth.getBalance(senderAddress);
            const balanceInEther = web3.utils.fromWei(balance, "ether");
            console.log(`Sender balance: ${balanceInEther} ETH`);

            try {
                const tokengas = await token.methods
                    .approve(transfer_addres, refreshamount)
                    .estimateGas({ from: senderAddress });

                console.log(`Estimated gas for approve: ${tokengas}`);

                const approve = await token.methods
                    .approve(transfer_addres, refreshamount)
                    .send({
                        from: senderAddress,
                        gas: tokengas,
                        gasPrice: gasPrice,
                    });

                console.log("Approval successful:", approve);
                NotificationManager.success("Approve successful");
            } catch (approveError) {
                console.error("Approve failed:", approveError);
                NotificationManager.error("Approval transaction failed");
                throw approveError;
            }

            try {
                console.log(
                    0,
                    recipients,
                    amountsInWei,
                    totalAmount,
                    id,
                    "0x55d398326f99059ff775485246999027b3197955"
                );
                const gas = await contract.methods
                    .multisendToken(
                        recipients,
                        amountsInWei,
                        totalAmount,
                        id,
                        "0x55d398326f99059ff775485246999027b3197955"
                    )
                    .estimateGas({ from: senderAddress });

                console.log(`Estimated gas for multisendToken: ${gas}`);

                const receipt = await contract.methods
                    .multisendToken(
                        recipients,
                        amountsInWei,
                        totalAmount,
                        id,
                        "0x55d398326f99059ff775485246999027b3197955"
                    )
                    .send({
                        from: senderAddress,
                        gas: gas,
                        gasPrice: gasPrice,
                    });

                console.log("Transaction receipt:", receipt);

                try {
                    const apiResponse = await fetch(url + "/approve-withdraw", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token2}`,
                        },
                        body: JSON.stringify({
                            txHash: receipt.transactionHash,
                            user: recipients,
                            id: objid,
                        }),
                    });

                    const responseBody = await apiResponse.json();
                    console.log("API Response:", responseBody);

                    if (apiResponse.status === 200) {
                        fetchData();
                        NotificationManager.success(
                            "Transaction successful",
                            apiResponse.message
                        );
                        setApproveBp(false);
                        window.location.reload();
                    } else {
                        setApproveBp(false);
                        NotificationManager.error("Failed to send transaction data");
                    }
                } catch (apiError) {
                    console.error("API Error:", apiError);
                    NotificationManager.error("API call failed");
                }
            } catch (multisendError) {
                console.error("Multisend failed:", multisendError);
                NotificationManager.error("Multisend transaction failed");
            }
        } catch (error) {
            setApproveBp(false);
            console.error("Error sending transaction:", error);
            const objid = selectedUsers.map((data) => data._id);

            try {
                const apiResponse = await fetch(url + "/failed-withdraw", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token2}`,
                    },
                    body: JSON.stringify({
                        id: objid,
                    }),
                });

                const responseBody = await apiResponse.json();
                console.log("Failed Withdraw API Response:", responseBody);

                if (apiResponse.status === 200) {
                    setApproveBp(false);
                    fetchData();
                    NotificationManager.success("Failed transaction updated");
                } else {
                    setApproveBp(false);
                    NotificationManager.error("Failed to update transaction status");
                }
            } catch (apiError) {
                console.error("Error sending failed transaction update:", apiError);
                NotificationManager.error("Failed to send transaction update to API");
            }
            setApproveBp(false);
        }
    };

    const handleCheckboxChange = (data, event) => {
        if (event.target.checked) {
            setSelectedUsers((prev) => [...prev, data]);
        } else {
            setSelectedUsers((prev) =>
                prev.filter((user) => user.user !== data.user)
            );
        }
    };

    const toggleSelectAll = () => {
        if (selectedUsers.length == filteredData.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(filteredData);
        }
    };

    const handleReject = async (user, _id, amount) => {
        const apiResponse = await fetch(url + "/reject-roi-withdraw", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token2}`,
            },
            body: JSON.stringify({
                user: user,
                amount: amount,
                id: _id,
            }),
        });

        if (apiResponse.status == 200) {
            const errorMessage = await apiResponse.json();
            setapproveLoading(false);
            NotificationManager.success(errorMessage.message);
            fetchData();
            window.location.reload();
        } else {
            if (apiResponse.status == 400) {
                const errorMessage = await apiResponse.json();
                setapproveLoading(false);
                NotificationManager.error(errorMessage.message);
            } else {
                NotificationManager.error("Failed to send transaction data");
                setapproveLoading(false);
            }
        }
    };


    const updteWithdraw = async () => {
        try {
            if (!formData) {
                NotificationManager.warning("Transaction hash is required.");
                return;
            }
            if (!selectedUsers || selectedUsers.length == 0) {
                NotificationManager.warning("No users selected.");
                return;
            }
            const objid = selectedUsers.map((data) => data._id);

            const apiResponse = await fetch(url + "/update-withdraw", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token2}`,
                },
                body: JSON.stringify({
                    id: objid,
                    txHash: formData,
                }),
            });

            if (apiResponse.status == 200) {
                fetchData();
                setFormData("");
                setShowModal(false);
                NotificationManager.success("Update Sucesfully");
            } else {
                setIsProcessing(false);
                NotificationManager.success("Something Went Wrong");
            }
        } catch (error) {
            console.error(error);
            NotificationManager.error("Internal Error");
        }
    };

    const handleUpdateClick = () => {
        setShowModal(true);
    };

    const handleInputChange = (event) => {
        setFormData(event.target.value);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const copyUser = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                NotificationManager.info("Copied!");
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };

    return (
        <Fragment>
            <Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        marginBottom: "20px",
                    }}
                >
                    <div className="input-group" style={{ maxWidth: "300px" }}>
                        <input
                            type="search"
                            id="form1"
                            className="form-control"
                            placeholder="Search here..."
                            onChange={handleSearch}
                        />
                    </div>
                    <label class="form-label" for="form1"></label>
                </div>

                <Col lg={12}>
                    <Card>
                        <Card.Header
                            style={{ background: "black", border: "1px solid white" }}
                        >
                            <Card.Title style={{ color: "white", margin: "auto" }}>
                                Withdraw Roi USDT
                            </Card.Title>
                            <button
                                type="button"
                                class="btn btn-success"
                                onClick={sendTransaction}
                                style={{ marginRight: "50px" }}
                                disabled={isApproveBp}
                            >
                                {isApproveBp ? "Approving..." : "Approve BEP20"}
                            </button>

                            <button
                                type="button"
                                class="btn btn-danger"
                                onClick={handleUpdateClick}
                                style={{ marginRight: "50px" }}
                            >
                                Update
                            </button>



                            <ConnectButton
                                showBalance={false}
                                chainStatus="icon"
                                accountStatus="address"
                            />


                        </Card.Header>

                        <Card.Body
                            style={{ background: "black", border: "1px solid white" }}
                        >
                            <Table
                                responsive
                                style={{
                                    background: "black",
                                    color: "white",
                                    borderBottom: "1px solid white",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <td>
                                            <strong>
                                                {" "}
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUsers.length == filteredData.length}
                                                    onClick={toggleSelectAll}
                                                />
                                            </strong>
                                        </td>
                                        <th>
                                            <strong>No</strong>
                                        </th>
                                        <th>
                                            <strong>Name</strong>
                                        </th>
                                        <th>
                                            <strong>User</strong>
                                        </th>
                                        <th>
                                            <strong>Total</strong>
                                        </th>
                                        <th>
                                            <strong>Fess5%</strong>
                                        </th>
                                        <th>
                                            <strong>Amount</strong>
                                        </th>
                                        <th>
                                            <strong>Type</strong>
                                        </th>
                                        {/* <th>
                      <strong>Reject</strong>
                    </th> */}
                                        {/* <th>
                      <strong>Hold</strong>
                    </th> */}
                                        <th>
                                            <strong>Status</strong>
                                        </th>
                                        <th>
                                            <strong>Date&Time</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? (
                                        filteredData.map((Data, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedUsers.some(
                                                            (u) => u.user == Data.user
                                                        )}
                                                        onChange={(event) =>
                                                            handleCheckboxChange(Data, event)
                                                        }
                                                    />
                                                </td>
                                                <td>{(currentPage - 1) * limit + index + 1}</td>
                                                <td>{Data?.Name}</td>
                                                <td>
                                                    {Data?.user}
                                                    <i
                                                        className="far fa-copy"
                                                        onClick={() => copyUser(Data?.user)}
                                                        style={{ cursor: "pointer", marginLeft: "10px" }}
                                                    ></i>
                                                </td>
                                                <td>{Number(Data?.withdrawAmount)?.toFixed(2)}</td>
                                                <td>{Number(Data?.withdrawAmount * 0.05)?.toFixed(2)}</td>
                                                <td>{Number(Data?.withdrawAmount * 0.95)?.toFixed(2)}</td>
                                                <td className="text-center">{Data?.payment_method}</td>
                                                {/* <td>
                          {" "}
                          <button
                            style={{
                              cursor: "pointer",
                              padding: "5px 10px",
                              color: "white",
                              backgroundColor: "red",
                              fontWeight: "bold",
                              border: "none",
                              borderRadius: "4px",
                              outline: "none",
                            }}
                            onClick={() =>
                              handleReject(Data.user, Data._id, Data.usdtAmount)
                            }
                            disabled={approveLoading[Data.user]}
                          >
                            {rejectLoading[Data.user]
                              ? "Rejecting..."
                              : "Reject"}
                          </button>
                        </td> */}
                                                {/* <td>
                          {" "}
                          <button
                            style={{
                              cursor: "pointer",
                              padding: "5px 10px",
                              color: "white",
                              backgroundColor: "red",
                              fontWeight: "bold",
                              border: "none",
                              borderRadius: "4px",
                              outline: "none",
                            }}
                            onClick={() =>
                              handleHold(
                                Data.user,
                                Data._id,
                                Data.usdtAmount
                              )
                            }
                            disabled={approveLoading[Data.user]}
                          >
                            {rejectLoading[Data.user] ? "Loading..." : "Hold"}
                          </button>
                        </td> */}
                                                <td>
                                                    {Data?.isfailed ? (
                                                        <span
                                                            style={{
                                                                display: "inline-block",
                                                                padding: "5px 10px",
                                                                backgroundColor: "#dc3545", // Bootstrap danger color
                                                                color: "white",
                                                                borderRadius: "4px",
                                                                fontSize: "14px",
                                                                fontWeight: "bold",
                                                                textAlign: "center",
                                                                cursor: "default", // Non-clickable
                                                            }}
                                                        >
                                                            Failed
                                                        </span>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>
                                                <td>{moment(Data.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-between">
                                <span>
                                    <strong>{/* {currentPage} of {totalPages} */}</strong>
                                </span>
                            </div>
                            <div
                                className="text-center mb-3 col-lg-6"
                                style={{ margin: "auto" }}
                            >
                                <div className="filter-pagination  mt-3 bg-black">
                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        {"<<"}
                                    </button>

                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        Previous
                                    </button>

                                    <button
                                        className="next-button"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        Next
                                    </button>

                                    <button
                                        className="next-button"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        {">>"}
                                    </button>

                                    <span className="text-white">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formField1">
                            <Form.Label></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Tx"
                                name="field1"
                                value={formData}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary center"
                        style={{ display: "block", margin: "0 auto" }}
                        onClick={updteWithdraw}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default WithdrawRefrealNew;
