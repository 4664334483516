import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import Web3 from "web3";
import { Row, Col, Card, Table, Modal, Button, Form } from "react-bootstrap";

import {
  url,
  withdrawRoi,
  withdrawWyzRoi_Live,
} from "../../../../services/api_function";
import { Link } from "react-router-dom";
import { DownloadExcel } from "react-excel-export";
import { web3 } from "../web3/web3Helper";

import {
  contract_address,
  ploygon_token,
  ploygon_token_abi,
  polygon_abi,
  token_abi,
  token_address,
  transfer_abi,
  transfer_addres,
  transfer_polygon,
  withdrawWyzContract,
  withdrawWyzContractAbi,
} from "../../../config/config";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const WithdrawWYZRoiLive = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [approveLoading, setapproveLoading] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const pageSize = 50;
  useEffect(() => {
    fetchData();
  }, [currentPage, search]);

  const token = useSelector((state) => state.auth.auth.token);
  const fetchData = async () => {
    try {
      const response = await withdrawWyzRoi_Live(
        currentPage,
        { searchQuery: search },
        pageSize,
        token
      );
      setFilteredData(response?.data);
      const pages = Math.ceil(response?.totalCount / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const sendTransaction = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(
      withdrawWyzContractAbi,
      withdrawWyzContract
    );

    const maxRetries = 3;
    let retries = 0;
    try {
      setIsProcessing(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      const senderAddress = accounts[0];
      const recipients = selectedUsers.map((data) => data.user);
      const amounts = selectedUsers.map((data) => data.withdrawAmount * 0.95);
      const id = selectedUsers.map((data) => data._id.toString().slice(0, 1));
      const objid = selectedUsers.map((data) => data._id);
      const totalAmount = web3.utils.toWei(
        selectedUsers
          .reduce((total, data) => total + data.withdrawAmount * 0.95, 0)
          .toString(),
        "ether"
      );

      const refreshamount = web3.utils.toWei(
        selectedUsers
          .reduce((total, data) => total + data.withdrawAmount * 1.5, 0)
          .toString(),
        "ether"
      );

      const amountsInWei = amounts.map((amount) =>
        web3.utils.toWei(amount.toString(), "ether")
      );
      const gasPrice = await web3.eth.getGasPrice();
      console.log(
        recipients,
        amountsInWei,
        totalAmount,
        id,
        senderAddress,
        "multisend"
      );
      const balance = await web3.eth.getBalance(senderAddress);
      const balanceInEther = web3.utils.fromWei(balance, "ether");
      console.log(`Sender balance: ${balanceInEther} ETH`);
      NotificationManager.success("Transaction in pending");
      const gas = await contract.methods
        .multisendToken(recipients, amountsInWei, totalAmount, id)
        .estimateGas({ from: senderAddress, value: totalAmount });

      console.log(
        recipients,
        amountsInWei,
        totalAmount,
        id,
        totalAmount,
        senderAddress,
        "after gas"
      );
      //   return;
      var receipt = await contract.methods
        .multisendToken(recipients, amountsInWei, totalAmount, id)
        .send({
          from: senderAddress,
          value: totalAmount,
          gas: gas,
          gasPrice: gasPrice,
        });
      console.log(receipt, ":::");
      //   }
      console.log(receipt, "Transaction successful");

      const apiResponse = await fetch(url + "/approve-withdraw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          txHash: receipt.transactionHash,
          user: recipients,
          id: objid,
        }),
      });

      if (apiResponse.status == 200) {
        NotificationManager.success(
          "Transaction successful",
          apiResponse.message
        );
        fetchData();
        setIsProcessing(false);
        window.location.reload();
      } else {
        NotificationManager.error("Failed to send transaction data");
        setIsProcessing(false);
      }
    } catch (error) {
      const objid = selectedUsers.map((data) => data._id);
      const apiResponse = await fetch(url + "/failed-withdraw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: objid,
        }),
      });

      if (apiResponse.status == 200) {
        setIsProcessing(false);
        fetchData();
        // window.location.reload();
      } else {
        setIsProcessing(false);
      }
      setIsProcessing(false);

      setIsProcessing(false);
      console.log("Error sending transaction:", error);
      NotificationManager.error("Error sending transaction:");
    }
  };

  const handleCheckboxChange = (data, event) => {
    if (event.target.checked) {
      setSelectedUsers((prev) => [...prev, data]);
    } else {
      setSelectedUsers((prev) =>
        prev.filter((user) => user.user !== data.user)
      );
    }
  };

  const toggleSelectAll = () => {
    if (selectedUsers?.length == filteredData?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(filteredData);
    }
  };

  const handleReject = async (user, _id, amount) => {
    setapproveLoading({ ...approveLoading, [user]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(url + "/reject-roi-withdraw", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user: user,
        amount: amount,
        id: _id,
      }),
    });

    if (apiResponse.status == 200) {
      const errorMessage = await apiResponse.json();
      setapproveLoading(false);
      NotificationManager.success(errorMessage.message);
      fetchData();
      window.location.reload();
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        setapproveLoading(false);
        NotificationManager.error(errorMessage.message);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setapproveLoading(false);
      }
    }
  };


  const updteWithdraw = async () => {
    try {
      if (!formData) {
        NotificationManager.warning("Transaction hash is required.");
        return;
      }
      if (!selectedUsers || selectedUsers?.length == 0) {
        NotificationManager.warning("No users selected.");
        return;
      }
      const objid = selectedUsers.map((data) => data._id);

      const apiResponse = await fetch(url + "/update-withdraw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: objid,
          txHash: formData,
        }),
      });

      if (apiResponse.status == 200) {
        fetchData();
        setFormData("");
        setShowModal(false);
        NotificationManager.success("Update Sucesfully");
      } else {
        setIsProcessing(false);
        NotificationManager.success("Something Went Wrong");
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Internal Error");
    }
  };

  const handleUpdateClick = () => {
    setShowModal(true);
  };

  const handleInputChange = (event) => {
    setFormData(event.target.value);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const copyUser = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.info("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Withdraw WYZ Roi
              </Card.Title>
              <button
                type="button"
                class="btn btn-success"
                onClick={sendTransaction}
                style={{ marginRight: "50px" }}
                disabled={isProcessing}
              >
                {isProcessing ? "Approving..." : "Approve WYZ"}
              </button>

              <button
                type="button"
                class="btn btn-danger"
                onClick={handleUpdateClick}
                style={{ marginRight: "50px" }}
              >
                Update
              </button>
              <ConnectButton  
                 showBalance={false} 
                 chainStatus="icon" 
                 accountStatus="address" 
                 />
              {/* <button type="button" class="btn btn-success">Approve</button> */}
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <td>
                      <strong>
                        {" "}
                        <input
                          type="checkbox"
                          checked={
                            selectedUsers?.length == filteredData?.length
                          }
                          onClick={toggleSelectAll}
                        />
                      </strong>
                    </td>
                    <th>
                      <strong>No</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>User</strong>
                    </th>
                    <th>
                      <strong>Total</strong>
                    </th>
                    <th>
                      <strong>Fess5%</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    {/* <th>
                      <strong>Reject</strong>
                    </th> */}
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData.map((Data, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedUsers.some(
                              (u) => u._id == Data._id
                            )}
                            onChange={(event) =>
                              handleCheckboxChange(Data, event)
                            }
                          />
                        </td>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{Data?.Name}</td>
                        <td>
                          {Data?.user}
                          <i
                            className="far fa-copy"
                            onClick={() => copyUser(Data?.user)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          ></i>
                        </td>
                        <td>{Number(Data?.withdrawAmount).toFixed(2)}</td>
                        <td>
                          {Number(Data?.withdrawAmount * 0.05).toFixed(2)}
                        </td>
                        <td>
                          {Number(Data?.withdrawAmount * 0.95).toFixed(2)}
                        </td>
                        <td className="text-center">{Data?.payment_method}</td>
                        {/* <td>
                          {" "}
                          <button
                            style={{
                              cursor: "pointer",
                              padding: "5px 10px",
                              color: "white",
                              backgroundColor: "red",
                              fontWeight: "bold",
                              border: "none",
                              borderRadius: "4px",
                              outline: "none",
                            }}
                            onClick={() =>
                              handleReject(
                                Data.user,
                                Data._id,
                                Data.withdrawAmount
                              )
                            }
                            disabled={approveLoading[Data.user]}
                          >
                            {rejectLoading[Data.user]
                              ? "Rejecting..."
                              : "Reject"}
                          </button>
                        </td> */}
                        <td>
                          {Data?.isfailed ? (
                            <span
                              style={{
                                display: "inline-block",
                                padding: "5px 10px",
                                backgroundColor: "#dc3545", // Bootstrap danger color
                                color: "white",
                                borderRadius: "4px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                textAlign: "center",
                                cursor: "default", // Non-clickable
                              }}
                            >
                              Failed
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{formatTimestamp(Data.createdAt)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formField1">
              <Form.Label></Form.Label>
              <Form.Control
                type="text"
                placeholder="Tx"
                name="field1"
                value={formData}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary center"
            style={{ display: "block", margin: "0 auto" }}
            onClick={updteWithdraw}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default WithdrawWYZRoiLive;
