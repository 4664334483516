import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { DownloadExcel } from "react-excel-export";
import { useLocation } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Deposite_StakingNew, Deposite_Wys, url } from "../../../../services/api_function";
import reactSelect from "react-select";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
const DepositNewSatking = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [fullcsvData, setfullcsvData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const limit = 100;
  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Deposite_StakingNew(
          currentPage,
          limit,
          search,
          token
        );
        const { data } = response;
        setFilteredData(response?.data);
        const pages = Math.ceil(response?.totalCount / limit);
        setTotalPages(pages > 0 ? pages : 1);

        const ModifiedData = data.map((item) => {
          const {
            Name,
            user,
            amount,
            ratio,
            token,
            txHash,
            createdAt,
            wyz_rate,
            wyz_amount,
          } = item;
          const Timestamp = moment(createdAt).format("DD-MM-YYYY HH:mm");
          return {
            Name: Name,
            user: user,
            wyz: wyz_amount,
            stUsdt: amount,
            token: token,
            ratio: ratio,
            wyz_rate: wyz_rate,
            txHash: txHash,
            createdAt: Timestamp,
          };
        });
        setcsvData(ModifiedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // handleDownload();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const columns = [
    { label: "Name", key: "Name" },
    { label: "User", key: "user" },
    { label: "WYZ", key: "wyz" },
    { label: "Stusdt", key: "stUsdt" },
    { label: "Token", key: "token" },
    { label: "Ratio", key: "ratio" },
    { label: "WyzRate", key: "wyz_rate" },
    { label: "Transaction ID", key: "txHash" },
    { label: "Date&Time", key: "createdAt" },
  ];
  // const handleDownload = async () => {
  //   try {
  //     const response = await axios.get(url + "/deposite-wyz-csv", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const data = response?.data?.ModifiedData;
  //     if (data) {
  //       setfullcsvData(data);
  //       setIsDataLoaded(true);
  //     } else {
  //       console.error("No data found.");
  //       setIsDataLoaded(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsDataLoaded(false);
  //   }
  // };

  const copyUser = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.info("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Deposit History
              </Card.Title>
              <CSVLink
                data={csvData}
                headers={columns}
                filename={"depositeWYZ.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Page CSV
              </CSVLink>
              {/* <CSVLink
                data={fullcsvData}
                headers={columns}
                filename={"depositefullWYZ.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Full CSV
              </CSVLink> */}
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>User</strong>
                    </th>

                    <th>
                      <strong> WYZ(stUSDT)</strong>
                    </th>
                    <th>
                      <strong> WYZ Quantity</strong>
                    </th>
                    <th>
                      <strong> stUSDT</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>
                    <th>
                      <strong>WYZ Rate</strong>
                    </th>
                    <th>
                      <strong>Ratio</strong>
                    </th>
                    {/* <th>
                      <strong>WYZ Rate</strong>
                    </th> */}
                    <th>
                      <strong>Transaction Id</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((data, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * limit + index + 1}</td>
                        <td>{data?.Name}</td>
                        <td>
                          {data?.user}
                          <i
                            className="far fa-copy"
                            onClick={() => copyUser(data?.user)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          ></i>
                        </td>

                        <td>{Number(data?.wyz_amount)?.toFixed(2)}</td>
                        <td>{Number(data?.wyz_quantity ?? 0)?.toFixed(2)}</td>
                        <td>{Number(data?.stusdt_amount)?.toFixed(2)}</td>
                        <td>{Number(data?.amount)?.toFixed(2)}</td>
                        <td>{Number(data?.wyz_rate ?? 0)?.toFixed(4)}</td>
                        <td>{data?.ratio}</td>
                        {/* <td>{Number(data?.wyz_rate).toFixed(4)}</td> */}
                        <td>
                          <a
                            href={`https://wyzthscan.org/tx/${data?.txHash}`}
                            className="text-white"
                            target="_blank"
                          >
                            {data?.txHash?.slice(0, 5)}...{" "}
                            {data?.txHash?.slice(-5)}
                          </a>
                        </td>
                        <td>{moment(data?.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DepositNewSatking;
